<template>

  <div class="trips-new">
    <main-nav />
    <main-titlebar showback="true" />

 
     <Form @submit="saveContact" :validation-schema="schema">
    <div class="trip-view-wrapper">
         <div v-for="contact in contacts" :key="contact.id" class="trip-view-row2">
        <Field name="id"  v-model="contact.id"  type="hidden"/>
        <Field name="address"  v-model="address"  type="hidden"/>



      <!-- Name -->
      <div class="form-group">
          <Field name="name" id="name" class="form-input" v-model="contact.name"  type="text"  placeholder="Naam contact/klant"/>
      </div>
      <div class="form-group">
        <ErrorMessage name="name" class="error-feedback" />
      </div>

      <!-- Email -->
      <div class="form-group">
          <Field name="email" id="email" class="form-input" v-model="contact.email"  type="text" placeholder="Email adres"/>
      </div>
      <div class="form-group">
        <ErrorMessage name="email" class="error-feedback" />
      </div>

      <!-- Phone -->
      <div class="form-group">
          <Field name="phone" id="phone" class="form-input" v-model="contact.phone"  type="text" placeholder="Telefoon"/>
      </div>

      <!-- Address -->
      <div class="form-group">
         <!-- <label for="origin">Pickup / origin</label> -->
        <GMapAutocomplete 
        id="origin"
        name="origin"
        :value="address"
        class="form-input"
        placeholder="Start / begin adres"
        @place_changed="setAddress"
        >
        </GMapAutocomplete>
      </div>
      <div class="form-group">
        <ErrorMessage name="origin" class="error-feedback" />
      </div>



      <!-- Zip + city -->
      <div class="form-group">
        <div class="col-2 col-2-c">
          <Field name="zip" id="zip" class="form-input" v-model="contact.zip"  type="text" placeholder="Postcode"/>

        </div>
        <div class="col-2 col-2-c">
 
          <Field name="city" id="city" class="form-input" v-model="contact.city"  type="text" placeholder="Stad / plaats"/>
        </div>
      </div>








      <!-- Note -->
      <div class="form-group">
          <Field name="note" as="textarea" v-model="contact.note" class="form-input" placeholder="Opmerkingen en/of notities"></Field>
      </div>



      
      </div>
      </div>
        <!-- Controls -->
      <div class="form-group" style="left:10%;">
        <button class="default-button bottom-button" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Opslaan</span>
        </button>
      </div>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {ref} from 'vue'

import ContactService from "../../services/contact-service";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";


export default {
  name: "trips-new",
  
  components: {
    Form,
    Field,
    ErrorMessage,
      'main-titlebar': require('@/components/titlebar.vue').default,
      'main-nav': require('@/components/mainmenu.vue').default,
  },
  data() {
    const name = ref('')
    const email = ref('')
    const phone = ref('')
    const address = ref('')
    const origin = ref('')
    const zip = ref('')
    const city = ref('')
    const note = ref('')
    const schema = yup.object().shape({
      // luggage: yup.string().required("Type is required"),
      // price: yup.string().required("Price is required"),
      name: yup.string().required("Naam is verplicht"),
      email: yup.string().required("Email is verplicht")
    });

   


    var contacts = [];
    var curContact = '';
    return {
      loading: false,
      zip2: '',
      city2: '',
      message: "",
      schema,
      name,
      email,
      phone,
      address,
      zip,
      city,
      note,
      contacts,
      origin,
      curContact
    };
  },
    mounted() {
    var targ = this;

   ContactService.getContact(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;
        targ.contacts = response.data;
        targ.curContact = response.data[0];
        this.address = targ.curContact.address;
      
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

  },
  computed: {

  },
  created() {
    if (this.loggedIn()) {
      console.log("New trip: user logged in: " + this.loggedIn());
    }
  },
  methods: {
    setAddress(targ) {
      this.setPlace(targ, "address")
    },
    setPlace(targ) {

      console.log("setPlace: details:" + JSON.stringify(targ));
      var address = targ.formatted_address
      var latlng = targ.geometry.location;
      var ac = address.split(", ");
      var addr = ac[0];
      var zipc = ac[1].split(" ");
      var zip = zipc[0] + " " + zipc[1];
      var city = zipc[2];
      var address_field = "address"; 
      this[''+address_field] = addr;
      this.curContact.zip = zip;
      this.curContact.city = city;

      console.log("setPlace:" + address_field + " | " + address + " | " + latlng);

    },
    saveContact(contact) {
     this.loading = true;
      console.log("saveContact>> contact:" + JSON.stringify(contact));

      ContactService.saveContact(contact).then(
        (response) => {
           console.log("saveContact>> response:" + JSON.stringify(response));
           if(response.status == "failed") {
            alert("saveContact>> Error: "+ response.message);
            // this.$router.go();

           } else {
             this.content = response.data;
            this.$router.push("/contacts/view/" + contact.id);
           }
        },
        (error) => {
          console.log("saveContact>> error:" + JSON.stringify(error));
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.$router.push("/contacts/view/" + contact.id);
        }
      );
    },
  },
};


</script>